$primary: #2EA6AF;
$success: #adc698;
$info: #2EA6AF;
$warning: #e5dba2;
$danger: #ed3517;
$inverse: #123456;

$body-bg: #fafafa;

$white: #fff !default;
$black: #000 !default;

$vote-yes: #339339;
$vote-no: #ee4125;
$vote-abstained: #e1e1df;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "inverse": $inverse,
    "background": $body-bg,
    "black": $black,
    "white": $white
  ),
  $theme-colors
);

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$black-color: #3C4858 !default;
