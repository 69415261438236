$bmd-font-weight-base: 400;

// wondering if any of these could still be refactored out, but are definitely in use.
$bmd-inverse: rgba($white, 1) !default;
$bmd-inverse-light: rgba($white, 0.84) !default;
$bmd-inverse-lighter: rgba($white, 0.54) !default;

$bmd-label-color: gray("700") !default;
$bmd-label-color-inner-focus: gray("700") !default; // e.g. radio label or text-muted not a control-label which is primary

$border-radius-base: 3px !default;
$border-radius-small: 2px !default;
$border-radius-large: 6px !default;
$border-radius-huge: 10px !default;
$border-radius-label: 12px !default;
$border-radius-extreme: 30px !default;

// Typography elements
$mdb-font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$mdb-text-color-light:  $white !default;
$mdb-text-color-light-hex: $white !default; // for contrast function in inverse
$mdb-text-color-primary: rgba(pol-color("black"), 0.87) !default;
$mdb-text-color-primary-hex: $black !default; // for contrast function in inverse
$icon-color: rgba(0,0,0,0.5) !default;

$mdb-label-color: rgba(pol-color("black"), 0.26) !default;
$mdb-label-color-toggle-focus: rgba(pol-color("black"), .54) !default;
