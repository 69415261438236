body {
  font-family: "Roboto", sans-serif;
  background-color: #e5e5e5;
}

.main-panel {
  .navbar-toggler {
    position: absolute;
    top: 15px;
  }

  legend {
    font-size: 16px;
    font-weight: 500;
    opacity: 0.7;
  }

  form {
    .form-group {
      margin-bottom: 3em !important;

      label {
        color: #495057 !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        opacity: 0.7;
      }

      select.selectpicker-candidate.form-control {
        position: static;
      }

      .switch-field {
        input {
          &:checked + label {
            color: #fff !important;
          }
        }

        label {
          border-radius: 5px;
          margin-right: 5px;
          font-weight: 500 !important;
        }
      }

      .form-check {
        label {
          font-size: 14px !important;
          font-weight: 400 !important;
          opacity: 1;
        }
      }
    }
  }
}

.navbar {
  .navbar-brand {
    font-size: 1em;
    font-weight: 500;
    position: absolute;
    top: 4rem;
    white-space: unset;
    display: block;
    max-width: 95%;
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 480px) {
      top: 2.5rem;
      height: 102px;
    }
  }

  .navbar-minimize {
    align-items: center;
    position: absolute;
    left: -2px;
    padding: 0;
    display: flex;
    top: 25px;

    @media (max-width: 991px) {
      display: none;
    }

    button {
      box-shadow: none;
      display: flex;
      color: #fff;
      align-items: center;
      justify-content: center;
      height: 22px;
      min-width: 22px;
      width: 15px;
    }

    i {
      font-size: 12px;
    }
  }
}

.councillor-details {
  margin-top: 130px;

  .councillor-details-section {
    display: none;
    overflow-x: hidden;
  }

  td {
    width: 50%;
    text-align: center;

    &:first-child {
      text-align: right;
    }

    &:nth-child(2) {
      text-align: left;
      padding-left: 15px;
    }
  }

  table {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 1.65em;
  }

  &__banner {
    background: $primary;
    position: relative;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }

    div {
      @media (max-width: 768px) {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
    }
  }

  &__name {
    position: absolute;
    font-weight: 500;
    color: #3c4858 !important;
    width: 100%;

    @media (max-width: 768px) {
      position: static;
      color: #fff !important;
    }
  }

  &__cantonal__name {
    top: 110px;
  }

  &__national__name {
    top: -70px;
  }

  &__office {
    position: initial;
    padding-top: 50px;
    color: #fff;
    padding-bottom: 15px;
    min-height: 120px;
  }

  &__avatar {
    margin-top: -111px;
    margin-left: auto;
    margin-right: auto;
    display: block !important;
    height: 160px;
    width: 160px;

    span,
    .image-container {
      box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 1);
      border-radius: 50%;
    }
  }

  &__more {
    cursor: pointer;
  }

  &__membership {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      justify-content: center;
      flex-wrap: wrap;
    }

    p {
      background: white;
      display: inline-block;
      border-radius: 5px;
      padding: 1px 5px;
      color: #3c4858;
      background-color: white;
    }
  }
}

.progress-bar {
  &__values {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  &__no {
    margin-right: 1.5rem;

    @media (max-width: 480px) {
      margin-right: 0 !important;
    }
  }

  &__green,
  &__red,
  &__gray {
    width: 20px;
    height: 20px;
    display: block;
    border-radius: 2px;
    margin-right: 5px;
  }

  &__green {
    background: #339339;
  }

  &__red {
    background: #fd3232;
  }

  &__gray {
    background: #e1e1df;
  }
}

.accepted {
  color: $vote-yes;
  font-weight: 700;
}

.bg-accepted {
  background: $vote-yes;
  color: color-yiq($vote-yes);
}

.rejected {
  color: $vote-no;
  font-weight: 700;
}

.bg-rejected {
  background: $vote-no;
  color: color-yiq($vote-no);
}

.abstained {
  color: color-yiq($vote-abstained);
  font-weight: 700;
}

.bg-abstained {
  color: $vote-abstained;
  background: color-yiq($vote-abstained);
}

img.desaturate {
  object-fit: cover;
  width: 100%;
  height: 100%;
  -webkit-filter: grayscale(100%);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

img.councillor-portait {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.portrait-wrapper {
  display: inline-block;
  width: 80%;
  padding: 3px;
  background-color: pol-color("primary");
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.cantonal-portrait-wrapper {
  display: inline-block;
  position: relative;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
}

.cantonal-portrait-wrapper a,
.cantonal-portrait-wrapper span {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid pol-color("primary");
}

.cantonal-portrait-wrapper a img,
.cantonal-portrait-wrapper span img {
  width: 100%;
  -webkit-filter: grayscale(100%);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

.cantonal-portrait-wrapper::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.canton-emblem {
  width: 22px;
}

.ct-emblem {
  max-width: 160px;
  max-height: 210px;
}

.councillor-details-table {
  margin-top: 1.65em;
}

.councillor-committee-memberships-table {
  margin-bottom: 1.65em;
}
.councillor-key {
  width: 120px;
  font-weight: 700;
  padding: 5px 5px 5px 0;
}

.councillor-separator {
  border-bottom: 1px solid gray("400");
}

.party-label,
.fraction-label {
  display: inline-block;
  text-align: center;
  width: 100%;
  border-radius: 4px;
  margin: 2px 0 2px 0;
  padding: 3px 11px;
  color: gray("100");
  background-color: gray("600");
  font-size: 10px;
  line-height: 12px;
}
.party-label.no-party {
  background-color: lightgray;
  color: #495057;
}
.party-label.sp,
.party-label.pss,
.fraction-label.s,
.fraction-label.fraction-s {
  background-color: #f0554d;
  color: #fff;
}

.party-label.cvp,
.party-label.pdc,
.party-label.ppd,
.party-label.m,
.fraction-label.m-ceb,
.fraction-label.fraction-m-ceb {
  background-color: #d6862b;
  color: #000;
}

.party-label.cspo {
  background-color: #e3ba24;
  color: #000;
}

.party-label.evp,
.party-label.pev {
  background-color: #deaa28;
  color: #000;
}

.party-label.gps,
.party-label.grüne,
.party-label.grune,
.party-label.pes,
.fraction-label.g,
.fraction-label.fraction-g {
  background-color: #84b547;
  color: #000;
}

.party-label.glp,
.party-label.pvl,
.fraction-label.gl,
.fraction-label.fraction-gl {
  background-color: #c4c43d;
  color: #000;
}

.party-label.ldp,
.party-label.pld {
  background-color: #618dea;
  color: "#000";
}

.party-label.bdp,
.party-label.pbd {
  background-color: #e6c820;
  color: #000;
}

.party-label.svp,
.party-label.udc,
.fraction-label.v,
.fraction-label.fraction-v {
  background-color: #4b8a3e;
  color: "#FFF";
}

.party-label.pda,
.party-label.pdt,
.party-label.pdl {
  background-color: #bf3939;
  color: "#FFF";
}

.party-label.lega {
  background-color: #9070d4;
  color: "#FFF";
}

.party-label.csp-ow,
.party-label.cspow {
  background-color: #b72733;
  color: "#fff";
}

.party-label.basta {
  background-color: #dc0000;
}

.party-label.edu {
  background-color: #a65e42;
  color: #fff;
}

.party-label.fdp-liberale,
.party-label.plr,
.party-label.fdp,
.fraction-label.rl,
.fraction-label.fraction-rl {
  background-color: #3872b5;
  color: #fff;
}

.party-label.die.mitte {
  background-color: #FF9B00;
}

.party-label.csp {
  background-color: #35AEB2;
}

.party-label:visited {
  color: gray("100");
}

.party-label:hover {
  color: #fff;
}

.fraction-label.c,
.fraction-label.fraction-c {
  background-color: #d6862b;
}

.fraction-label.bd,
.fraction-label.fraction-bd {
  background-color: #ffdf01;
}

.fraction-label.na,
.fraction-label.fraction-na {
  background-color: #e1e1df;
}

.vote-label {
  display: inline-block;
  border-radius: 5px;
  margin: 2px 0 2px 0;
  padding: 1px 5px;
  color: gray("100");
}

.vote-label.yes {
  background-color: $vote-yes;
}

.vote-label.no {
  background-color: $vote-no;
}

.vote-label.abs {
  background-color: $vote-abstained;
  color: #414141;
}

h4.session-line {
  margin-bottom: 25px;
}

#back-to-top {
  display: none;
  background-color: gray("100");
  width: 30px;
  height: 30px;
  margin: 30px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s;
  z-index: 99;
  color: #414141;
  padding: 5px;
  vertical-align: middle;
}

#back-to-top:hover {
  color: gray("900");
  background-color: gray("200");
  border: solid 1px gray("300");
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.075) inset;
}

[data-toggle="collapse"][aria-expanded="false"] .more-less::before {
  content: "\002b";
}

[data-toggle="collapse"][aria-expanded="true"] .more-less::before {
  content: "\2212";
}

@media (min-width: 1200px) {
  #back-to-top {
    bottom: 30px;
    right: 30px;
    margin: 30px;
    position: fixed;
  }
}

@media (max-width: 768px) {
  #back-to-top {
    bottom: 10px;
    right: 10px;
    margin: 10px;
  }
}

hr.affair-separator {
  border-bottom: 1px solid #d6d6cd;
  clear: both;
}

hr.same-affair-separator {
  border-bottom: 1px solid #e6e6e1;
  clear: both;
}

td.canton-crest,
td.topics-symbol {
  width: 35px;
  padding: 5px 10px 0 0;
}

.canton-overview-crest,
.party-overview-logo {
  max-width: 30px;
  max-height: 36px;
}

.nonattendance,
.coalition,
.parl-proposals {
  background-color: gray("200");
  margin-top: 14px;
  height: 25px;
  border-radius: 5px;
}

.stacked-bar-1 {
  height: 25px;
  background-color: #f3a683;
}

.stacked-bar-2 {
  height: 25px;
  background-color: #778beb;
}

.stacked-bar-3 {
  height: 25px;
  background-color: #f7d794;
}

.stacked-bar-4 {
  height: 25px;
  background-color: #cf6a87;
}

.stacked-bar-5 {
  height: 25px;
  background-color: #e77f67;
}

.stacked-bar-6 {
  height: 25px;
  background-color: #f5cd79;
}

.stacked-bar-7 {
  height: 25px;
  background-color: #f19066;
}

.stacked-bar-8 {
  height: 25px;
  background-color: #e15f41;
}

.stacked-bar-9 {
  height: 25px;
  background-color: #546de5;
}

.stacked-bar-10 {
  height: 25px;
  background-color: #786fa6;
}

.stacked-bar-11 {
  height: 25px;
  background-color: #c44569;
}

.stacked-bar-12 {
  height: 25px;
  background-color: #63cdda;
}

.stacked-bar-13 {
  height: 25px;
  background-color: #f8a5c2;
}

.stacked-bar-14 {
  height: 25px;
  background-color: #596275;
}

.stacked-bar-15 {
  height: 25px;
  background-color: #ea8685;
}

.stacked-bar-16 {
  height: 25px;
  background-color: #f78fb3;
}

.stacked-bar-17 {
  height: 25px;
  background-color: #574b90;
}

.stacked-bar-18 {
  height: 25px;
  background-color: #e66767;
}

.stacked-bar-19 {
  height: 25px;
  background-color: #3dc1d3;
}

.stacked-bar-20 {
  height: 25px;
  background-color: #303952;
}


.nonattendance-numbers,
.coalition-numbers,
.stacked-bar-stats {
  color: gray("100");
  vertical-align: middle;
}

.negative .nonattendance-bar,
.negative .coalition-bar {
  background-color: transparent;
}

.negative .nonattendance-numbers,
.negative .coalition-numbers,
.negative .stacked-bar-stats {
  color: #525252;
}

.stacked-bar-stats {
  text-overflow: ellipsis;
  vertical-align: middle;
}

.nonattendance-numbers-inverted,
.coalition-numbers-inverted {
  color: #414141;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  vertical-align: middle;
}

.attendance-stats {
  margin-top: 15px;
}

.attendance-more {
  margin-top: 13px;
}

.coalition-stats,
.coalition-more {
  margin-top: 16px;
}

.attendance-details,
coalition-details {
  vertical-align: middle;
}

.card-details-toggle {
  margin-top: 1em;
  text-align: right;
}

.ranking-portrait {
  display: inline-block;
  width: 100%;
}

input[type="radio"]:checked + .checked {
  background-color: #0e9cbc;
  border-color: #0e9cbc;
}

.switch-field {
  overflow: hidden;
}

.switch-field .form-check {
  margin-bottom: 0;
}

.switch-field .checkbox,
.switch-field .radio {
  margin-top: inherit;
  margin-bottom: inherit;
}

.switch-title {
  margin-bottom: 6px;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  float: left;
}

.switch-field label {
  display: inline-block;
  width: 150px;
  background-color: gray("100");
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: 6px 14px;
  margin-bottom: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3),
    0 1px rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: $primary;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}

.voting-behaviour {
  margin-left: -15px;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: $primary;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}

.outcome {
  background-color: #e6e6e1;
  margin: 14px auto 0 auto;
  height: 25px;
  border-radius: 5px;
}

.stacked-bar-yes,
.stacked-bar-no,
.stacked-bar-abs {
  height: 25px;
}

.stacked-bar-yes {
  background-color: $vote-yes;
}

.stacked-bar-no {
  background-color: $vote-no;
}

.stacked-bar-abs {
  background-color: $vote-abstained;
}

.stacked-bar-outcome {
  color: #414141;
  vertical-align: middle;
  overflow: hidden;
  vertical-align: middle;
}

div#poll-result {
  margin: 0 auto;
  position: relative;
  width: 20%;
  font-size: 1.5em;
  text-align: center;
}

p.yes,
p .yes {
  color: $vote-yes;
  font-weight: 700;
}
p.no,
p .no,
span.no.wired {
  color: $vote-no;
  font-weight: 700;
}

.badge-primary a {
  color: pol-color("white");
}

.card-button {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;

  :after {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }

  .card-title {
    margin-top: 10px;
  }

  .card-body {
    padding: 15px !important;
    margin: 0 !important;
  }

  h1 {
    small {
      font-size: 18px;
      display: inline-flex;
      height: 0;

      :first-child {
        position: relative;
        top: -17px;
        font-size: 26px;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    max-width: 240px;
    margin: 10px auto;

    li {
      color: #999999;
      text-align: center;
      padding: 12px 0;
      border-bottom: 1px solid rgba(153, 153, 153, 0.3);

      :last-child {
        border: 0;
      }

      b {
        color: $black-color;
      }

      i {
        top: 6px;
        position: relative;
      }
    }
  }

  [class*="bg-"] ul li {
    color: pol-color("white");
    border-color: rgba(255, 255, 255, 0.3);
  }

  [class*="bg-"] ul li b {
    color: pol-color("white");
    font-weight: 700;
  }

  [class*="bg-"] [class*="text-"] {
    color: pol-color("white") !important;
  }
}

.image-container {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
}
.image-container img {
  position: absolute;
  left: 0;
  top: 0;
}

.nav.nav-pills {
  margin-bottom: 20px;
}

.tooltipster-base {
  position: absolute;
}

.tooltipster-sidetip {
  background: none !important;
  border: none !important;
  pointer-events: none !important;
  overflow: visible;

  * {
    pointer-events: auto;
  }

  &.tooltipster-top .tooltipster-arrow-background {
    border-top-color: pol-color("white") !important;
  }

  &.tooltipster-bottom .tooltipster-arrow-background {
    border-bottom-color: pol-color("white") !important;
  }

  .tooltipster-box {
    background: none !important;
    border: none !important;
    overflow: visible;

    .tooltipster-content {
      padding: 0 !important;
      overflow: visible;

      .card {
        margin-bottom: 2px;
      }

      .card-with-header {
        margin-top: 22px;
      }
    }
  }
}

.progress {
  height: 25px;
}

.card-list .card {
  margin-top: 15px;
  margin-bottom: 15px;
}

.councillor-card-list .card {
  height: calc(100% - 60px);
}


.card-header-info a {
  color: $white;
}

table.dataTable thead .sorting::before,
table.dataTable thead .sorting_asc::before,
table.dataTable thead .sorting_desc::before,
table.dataTable thead .sorting_asc_disabled::before,
table.dataTable thead .sorting_desc_disabled::before {
  left: 0.5em;
}
table.dataTable thead .sorting::after,
table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::after,
table.dataTable thead .sorting_asc_disabled::after,
table.dataTable thead .sorting_desc_disabled::after {
  left: 1em;
}

table.dataTable.table-condensed > thead > tr > th {
  padding-left: 30px !important;
  padding-right: 5px !important;
}

.list-group-item {
  border: solid 1px rgba(0, 0, 0, 0.14);
  margin: -1px 0 0 -1px;

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.chart {
  height: 250px;
}

.dropdown-menu {
  z-index: 98;
}

.form-check .form-check-input:not(:checked) + .form-check-sign:before,
.form-check .form-check-input:checked + .form-check-sign:before {
  animation: none;
}

.form-check {
  .checkbox-tree-level-0 {
    margin-left: 0;
  }
  .checkbox-tree-level-1 {
    margin-left: 1em;
  }

  .form-check-label {
    .form-check-sign {
      height: 20px;
    }

    .form-check-sign:before {
      width: 10px;
      height: 10px;
      left: 4px;
      top: 4px;
    }
  }
}

.councillor-info {
  &:hover {
    .councillor-info__content {
      display: block;
    }
  }

  i {
    margin-bottom: 19px;
  }

  &__content {
    display: none;
    position: absolute;
    background: #fff;
    color: #000;
    min-width: 350px;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(86, 163, 185, 0.4);

    @media (max-width: 590px) {
      left: -165px;
      width: 300px;
      z-index: 9999;
    }
  }
}

.groups-tooltip {
  position: absolute;
  right: 1px;
  cursor: pointer;
  color: $primary;
  font-size: 16px;
  z-index: 99;
}

@each $color, $value in $theme-colors {
  .badge-#{$color} a {
    color: color-yiq($value);
  }
}

.modal .modal-header .close {
  padding: 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
  z-index: 2;
}

@media (max-width: 767px) {
  .xs-minify {
    font-size: 0.7rem !important;
    line-height: initial !important;
  }
}

#react_timeline_root {
  position: relative;
  z-index: 4;
}

div.councillor-avatar {
  background-size: cover !important;
  background-position: center !important;
  min-height: 100%;
}

.filter-grayscale {
  filter: grayscale(1);
}

div.dataTables_wrapper div.dataTables_length select {
  padding-left: 10px;
}

.modal .modal-header button.close {
  color: inherit;
}

.cursor-pointer {
  cursor: pointer;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 24%, #eeeeee 44%);
  background-size: cover;
  height: 96px;
  position: relative;
}

.link-disabled {
  pointer-events: none;
}

.MuiInputBase-root span.bmd-form-group textarea {
  overflow: auto !important;
}

.shadow {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.fullwidth {
  width: 100%;
}

.card-shadow {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.border-radius {
  border-radius: 6px;
}

// overwite bootstrap styled
b,
strong {
  font-weight: 900;
}

@keyframes slideInRight {
  from {
    max-width: 0px;
    transform: translateX(30px);
  }

  to {
    max-width: 1000px;
    transform: translateX(0px);
  }
}

.card-profile{
  .card-header {
    background: transparent;
  }
  .card-avatar {
    width: 130px;
    height: 130px;

    margin: -50px auto 0;
    border-radius: 50%;
    overflow: hidden;
    padding: 0;
    display: flex;

    @include shadow-big();

    & a.avatar-link {
      display: flex;
      margin: auto;
      justify-content: center;
      align-items: center;
    }

    & + .card-body {
      margin-top: 15px;
      text-align: center;
    }
    img {
      width: 100%;
      object-fit: scale-down;
      background-color: white;
    }
  }

  margin-top: 50px;
}

// Removed with material dashboard
.btn {
  margin: .3125rem 1px;
}

.progress.clip.nonattendance {
  .top-container {
    position: absolute;
    z-index: 1;
    height: 25px;
    background-color: $primary;
    color: white;
    line-height: 25px;
    white-space: nowrap;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .bottom-container {
    z-index: 0;
    height: 25px;
    background-color: transparent;
    color: #525252;
    line-height: 25px;
    white-space: nowrap;
  }
}

.table-cell-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

// IE doesn't support -webkit-line-clamp: 2;
// so we need to set the max-height manualy for that "Browser"
// [POL-4509]
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .table-cell-name {
    max-height: calc(1em * 1.5 * 2);
    display: block;
  }
}

h4.national-committee-list__item__name {
  overflow: hidden;
  text-overflow: ellipsis;
}

* {
  font-family: 'Roboto', sans-serif;
}

b, strong {
  font-weight: 600;
}

.row .col-md-4.action-input-container {
  padding-left: 2px !important;
  padding-right: 2px !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

#root {
  width: 100vw;
}

.custom_fields_container{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: 8px;
}